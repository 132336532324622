import React from 'react';
import './App.css';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './plugins/appInsights';

import Header from './components/Header';
import VinkaMap from './components/VinkaMap';
import OrderForm from './components/OrderForm';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { getAuthProvider } from './authProvider';
import MessagePanel from './components/MessagePanel/MessagePanel';

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <AzureAD provider={getAuthProvider()}>
                    {({ login, logout, authenticationState }) => (
                        <React.Fragment>
                            <Header>
                                {authenticationState === AuthenticationState.Authenticated && (
                                    <button className="btn btn-outline-light" onClick={logout}>Logout</button>
                                )}
                                {authenticationState === AuthenticationState.Unauthenticated && (
                                    <button className="btn btn-outline-light" onClick={login}>Login</button>
                                )}
                            </Header>

                            <div className="main-content">
                                <VinkaMap />

                                {authenticationState === AuthenticationState.Authenticated && (
                                    <OrderForm />
                                )}

                                <MessagePanel />
                            </div>
                        </React.Fragment>
                    )}
                </AzureAD>
            </div>
        );
    };
}

export default withAITracking(reactPlugin, App);
