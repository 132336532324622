import { observable } from 'mobx';

export default class Payment {
    type: string = 'unknown';
    instrumentId?: string;
    info?: string;
    @observable discount?: ExternalCompanyDiscount;
    @observable description?: string = '';

    constructor() {
        this.type = 'invoice';
    }
}
