import { action, observable } from 'mobx';
import Location from './Location';
import Rider from './Rider';
import Customer from './Customer';
import AppConfig from '../conf';
import Payment from './Payment';

export default class Order {
    @observable id: string = '';
    @observable sutiId: string = '';
    @observable externalId: string = '';
    @observable requestedTime: Date = new Date();
    @observable rider: Rider | null = null;
    @observable customer: Customer | null = null;
    @observable costCenter: string = '';
    @observable dispatcherInfo: string = '';
    @observable driverInfo: string = '';
    @observable projectCode: string = '';
    @observable locations: Location[] = [];
    @observable attributes: IAttribute[] = [];
    @observable price: number = 0;
    @observable tax: number = 0;
    @observable taxRate: number = 10;
    @observable fleetPrice: number | null = null;
    @observable fleetId: string | null | undefined = '';
    @observable vehicle: Vehicle | null = null;

    @observable status: string = 'pending';
    @observable source: Source | null = null;
    @observable operator: Operator | null = null;
    @observable contract: Contract | null = null;
    @observable payment: Payment;
    @observable temporaryRider: string = '';

    constructor() {
        this.addLocation('pick');
        this.addLocation('drop');
        this.payment = new Payment();
        this.operator = { id: "taksipartners", name: "Taksi Partners" };
    }

    @action
    clearRider() {
        this.rider = null;
        this.clearCustomer();
    }

    @action
    clearCustomer() {
        this.customer = null;
        this.payment.discount =
        {
            type: 'percentage',
            description: '',
            value: 0
        };
    }

    @action
    setRider(rider: Rider | null) {
        this.rider = rider;
        this.clearCustomer();
    }

    @action
    setCustomer(customer: Customer | null) {
        this.customer = customer;
        if (customer) {
            this.costCenter = customer.costCenter;
            this.payment.instrumentId = customer.paymentInstrumentId;
        }
        if (this.customer?.discount) {
            const type = this.customer?.discount.type.toLowerCase() as ('percentage' | 'fixed' | 'factor');

            let discount: ExternalCompanyDiscount = {
                type: type,
                description: this.customer?.discount.description,
                value: this.customer?.discount.value
            };

            this.setDiscount(discount);
        }
        if (customer?.defaultCarAttributes){
            this.attributes = customer.defaultCarAttributes;
        } else {
            this.attributes = []
        }
    }

    @action
    setPayment(payment: Payment) {
        this.payment = payment;
    }

    @action
    setPaymentType(paymentTypeId?: string) {
        this.payment.type = paymentTypeId || 'unknown';
    }

    @action
    setDiscount(discount?: ExternalCompanyDiscount) {
        this.payment.discount = discount;
    }

    @action
    setOperator(operator: Operator) {
        console.log("Setting operator to ", operator);
        this.operator = operator;
        this.clearRider();
    }

    @action
    addLocation(type: string = 'checkpoint') {
        // TODO: implement some clever type detector here
        const location = new Location(type);
        this.locations.push(location);
    }

    @action
    addLocationAt(type: string = 'checkpoint', position: number = -1) {
        // TODO: implement some clever type detector here
        const location = new Location(type);
        this.locations.splice(position, 0, location);
    }

    @action
    removeLocationAt(index: number) {
        this.locations.splice(index, 1);
    }

    @action
    updateFromResponse(response: any) {
        this.price = (response.price || {}).total;
        this.fleetPrice = (response.fleetPrice || {}).total;
        this.tax = (response.price || {}).tax;
        this.taxRate = (response.price || {}).taxRate;
        this.id = response.id;
        this.fleetId = (response.fleet || {}).id;
        this.status = response.status;
    }

    @action
    updateFromSocket(status: string, response: any) {
        this.fleetId = response.fleetId;
        this.sutiId = response.sutiOrderId;
        this.status = status;
    }

    @action
    clone() {
        const clone: Order = new Order();

        clone.operator = this.operator;
        
        clone.rider = new Rider();
        this.copyProps(clone.rider, this.rider);

        clone.customer = new Customer();
        this.copyProps(clone.customer, this.customer);

        clone.costCenter = this.costCenter;

        clone.dispatcherInfo = this.dispatcherInfo;
        clone.driverInfo = this.driverInfo;

        clone.projectCode = this.projectCode;

        clone.locations = [];
        this.locations.forEach(location => {
            let loc = new Location(location.type);
            this.copyProps(loc, location);
            clone.locations.push(loc);
        });

        clone.price = this.price;
        clone.fleetPrice = this.fleetPrice;
        clone.tax = this.tax;
        clone.taxRate = this.taxRate;

        clone.attributes = [];
        this.attributes.forEach(item => {
            clone.attributes.push({
                id: item.id,
                name: item.name
            });
        });

        clone.payment = new Payment();
        this.copyProps(clone.payment, this.payment);

        return clone;
    }

    @action
    reverse() {
        this.locations = this.locations.reverse();
        this.locations.forEach(location => {
            if (location.type === 'pick') location.type = 'drop';
            else if (location.type === 'drop') location.type = 'pick';
        });
        return this;
    }

    @action
    copyProps(toObj, fromObj) {
        for (let key in fromObj) {
            if (fromObj.hasOwnProperty(key) && toObj.hasOwnProperty(key)) {
                toObj[key] = fromObj[key];
            }
        }
    }

    toApiFormat() {
        if (!this.payment.discount || !this.payment.discount.value) {
            this.payment.discount = undefined;
        }

        const apiFormat = {
            data: {
                externalId: Order.genUUID(), // TODO: this should probably be updated with more reliable data
                waypoints: this.locations.map(i => (Object.assign(
                    (i.type === 'pick' ? { requestedTime: this.requestedTime.toISOString() } : {}), {
                    type: i.type,
                    rider: (this.rider ? this.rider.toApiFormat() : null),
                    location: i.toApiFormat()
                }
                ))),
                capacity: [
                    {
                        unitId: 1,
                        count: 1
                    }
                ],
                attributes: !this.attributes ? [] : this.attributes.map(i => Object.assign({}, i)), // duplicate objects, do not use originals
                customer: {
                    id: (this.customer && this.customer.id) ? this.customer.id : null,
                    name: this.customer?.name,
                    externalId: (this.customer && this.customer.externalId) ? this.customer.externalId : 'unknown',
                    costCenter: this.costCenter,
                    email: this.customer?.email,
                    phone: this.customer?.phone,
                    projectCode: this.projectCode
                },
                operator: {
                    id: this.operator?.id,
                    name: this.operator?.name
                },
                dispatcherInfo: this.dispatcherInfo,
                driverInfo: this.driverInfo,
                source: { app: AppConfig.app.name },
                contract: this.contract,
                payment: this.payment,
            }
        };

        this.nullify(apiFormat);

        return apiFormat;
    }

    @action
    fromApiFormat(apiOrder: IOrder) {
        this.id = apiOrder.id;
        this.sutiId = apiOrder.sutiId || '';
        this.externalId = apiOrder.externalId || '';
        this.fleetId = apiOrder.fleet ? apiOrder.fleet.id : null;

        const rider = Order.findRider(apiOrder);
        if (rider) {
            this.rider = new Rider();
            this.rider.fromApiFormat(rider);
        }

        this.customer = new Customer();
        this.customer.fromApiFormat(apiOrder.customer);

        this.costCenter = apiOrder.customer.costCenter || '';

        this.dispatcherInfo = apiOrder.dispatcherInfo || '';
        this.driverInfo = apiOrder.driverInfo || '';
        this.projectCode = apiOrder.customer.projectCode || '';

        this.attributes = apiOrder.attributes;
        this.price = apiOrder.price ? apiOrder.price.total : 0;
        this.fleetPrice = apiOrder.fleetPrice ? apiOrder.fleetPrice.total : null;
        this.tax = apiOrder.price ? (apiOrder.price.tax ? apiOrder.price.tax : 0) : 0;
        this.taxRate = apiOrder.price ? (apiOrder.price.taxRate ? apiOrder.price.taxRate : 0) : 10;

        const pickWayPoint = apiOrder.waypoints.find(i => i.type === Location.PICK);
        const dropWayPoint = apiOrder.waypoints.find(i => i.type === Location.DROP);
        this.requestedTime = pickWayPoint && pickWayPoint.requestedTime ? new Date(pickWayPoint.requestedTime) : new Date();

        if (pickWayPoint) {
            this.locations.filter(i => i.type === Location.PICK).forEach(i => i.fromApiFormat(pickWayPoint.location));
        }

        if (dropWayPoint) {
            this.locations.filter(i => i.type === Location.DROP).forEach(i => i.fromApiFormat(dropWayPoint.location));
        }

        this.status = apiOrder.status;

        this.operator = {
            id: apiOrder.operator.id,
            name: apiOrder.operator.name
        };

        this.contract = apiOrder.contract || null;

        if (apiOrder.source) {
            this.source = Object.assign({}, apiOrder.source);
        }

        if (apiOrder.vehicle) {
            const phoneInfo = apiOrder.vehicle.contactInfo ? apiOrder.vehicle.contactInfo.find(i => i.type === 'phone') : {};

            this.vehicle = {
                id: apiOrder.vehicle.id,
                phone: phoneInfo.info
            }
        }

        this.payment = apiOrder.payment || new Payment();
    }

    nullify(o) {
        for (let k in o) {
            if (o.hasOwnProperty(k)) {
                if ('' === o[k]) {
                    o[k] = null;
                } else if (typeof o[k] === 'object') {
                    this.nullify(o[k]);
                }
            }
        }
    }

    static genUUID(): string {
        // eslint-disable-next-line
        return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c => (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16));
    }

    /**
     * This function is needed due to the fact, that rider should be set in both pick and drop, but somehow might be missing in pick...
     * Lets try to find the first waypoint with the rider set and return him. If not found - return an empty set.
     *
     * @param rawOrder
     */
    static findRider(rawOrder: any): IRider | null {
        try {
            return rawOrder.waypoints.find(i => i.rider).rider;
        } catch (e) {
            return null;
        }
    }
}
