import React from 'react';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

const options = [
    //{"value": 1504, "name": "Pet friendly"},
    //{"value": 1600, "name": "Test vehicle"},
    {"id": 1601, "name": "Combi"}, //Station wagon
    {"id": 1619, "name": "Bus"},
    {"id": 9999, "name": "Parcel"},
    {"id": 1618, "name": "Sedan"},
    {"id": 1607, "name": "Premium"},
    //{"value": 1612, "name": "Lowrider"},
    {"id": 1615, "name": "Lift"}, //Wheelchair lift
    {"id": 1614, "name": "Ramp"}, //Paratransit
    //{"value": 1616, "name": "Stairclimber"},
    {"id": 1613, "name": "Stretcher"},
    //{"value": 1621, "name": "Non smoking"},
    //{"value": 1627, "name": "Electric wheelchair"},
    //{"value": 1628, "name": "Front seat"},
    //{"value": 1632, "name": "Multiple wheelchairs"},
    //{"value": 1690, "name": "Alcometer"}
];

type ComponentParams = {
    value: IAttribute[],
    onChange: Function,
}

const AttributeSelect = ({value, onChange}: ComponentParams) => {
    const { t } = useTranslation();

    const setNoOptionsMessage = ({inputValue}) => {
        return inputValue === ''
            ? t('page.order.attributesStartSearch')
            : t('page.order.attributesNoOptions', { q: inputValue });
    };

    return (
        <Select
            isMulti={true}
            isSearchable={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            placeholder={t('page.order.attributesPlaceholder')}
            classNamePrefix="react-select"
            value={value}
            options={options}
            getOptionLabel={o => t(`attribute.${o.id}`)}
            getOptionValue={o => o.id}
            noOptionsMessage={setNoOptionsMessage}
            onChange={onChange}
        />
    );
};

export default observer(AttributeSelect);
