import {action, observable} from 'mobx';
import moment from 'moment';
import Order from '../models/Order';

export default class MessageStore {
    @observable messages: Message[] = [];

    @action
    addMessage(message: Message) {
        message.ts = moment().unix();
        message.id = Order.genUUID();
        this.messages.push(message);

        setTimeout( this.removeMessage.bind(this), 10000, message)
    }

    @action
    removeMessage(message: Message) {
        this.messages = this.messages.filter(i => i.id !== message.id);
    }
}
