import io from 'socket.io-client';
import confs from '../conf';

class SocketService {
    private socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;
    private listeners = {};

    constructor() {
        //TODO: uncomment after endpoint is working
        //this.connect();

        this.addListener('ws.greet', console.log);
    }

    public connect() {
        this.socket = io(confs.socketIO.baseUrl, { path: confs.socketIO.path });
    }

    public disconnect() {
        this.socket.disconnect();
    }

    messageHandler(type: string, msg: any) {
        this.listeners[type].forEach(f => f(msg));
    }

    addListener(type: string, f: Function) {
        //TODO: uncomment after endpoint is working
        /*         if (!this.listeners.hasOwnProperty(type)) {
                    this.listeners[type] = [];
                    this.socket.on(type, (msg: any) => this.messageHandler(type, msg));
                }
        
                this.listeners[type].push(f); */
    }
}

export default SocketService;
