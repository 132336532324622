import { action, observable } from 'mobx';
import Order from '../models/Order';
import OrderApi from './orderApi';
import confs from '../conf';

export default class OrderStore {
    @observable newOrder: Order;
    
    orderApi: OrderApi;

    constructor() {
        this.orderApi = new OrderApi(confs.api.url);

        this.newOrder = new Order();
    }

    resetNewOrder() {
        this.newOrder = new Order();
    }

    confirmOkListener(msg: any) {
        msg = msg.payload.headers;

        if (this.newOrder.id === msg.orderId) {
            this.newOrder.updateFromSocket('ok', msg);
        }
    }

    confirmFailedListener(msg: any) {
        msg = msg.payload.headers;

        if (this.newOrder.id === msg.orderId) {
            this.newOrder.updateFromSocket('failed', msg);
        }
    }

    setNewOrder(order) {
        this.newOrder = order;
    }

    @action
    async add(order: Order) {
        return await this.orderApi.add(order);
    }

    @action
    async search(q: string, startTime: Date, endTime: Date): Promise<Order[]> {
        return await this.orderApi.search(q, startTime, endTime);
    }

    @action
    async delete(id: string): Promise<number> {
        return await this.orderApi.delete(id);
    }

    @action
    async confirm(id: string) {
        return await this.orderApi.confirm(id);
    }
}
