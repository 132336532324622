import 'flatpickr/dist/flatpickr.css'; // TODO: should we move it to the `App.jsx` or `index.jsx`? Or leave here?
import { Finnish } from 'flatpickr/dist/l10n/fi.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import 'moment/locale/fi';

type ComponentParams = {
    value: Date,
    onChange: Function
}

const DateTimePicker = React.forwardRef(({ value, onChange }: ComponentParams, ref) => {

    const { i18n, t } = useTranslation();
    const locale = i18n.language === 'fi' ? Finnish : null;

    const [isTimeFormatted, setTimeFormat] = useState(false);

    const changeTime = (e) => {
        if (!e || e < new Date()) {
            setTimeFormat(false);
            onChange(new Date());
        } else {
            setTimeFormat(true);
            onChange(e);
        }
    };

    const setFormat = (e) => {
        return isTimeFormatted ? moment(e).locale(i18n.language).format('L LT') : t('page.order.currentTime');
    };

    return (
        <div className="position-relative">
            <Flatpickr
                key={locale}
                className="form-control"
                data-enable-time
                data-min-date={"today"}
                value={value}
                ref={ref}
                onValueUpdate={(e) => changeTime(e[0])}
                options={{
                    locale: locale,
                    formatDate: e => setFormat(e)
                }}
            />
            <button type="button" className="close close-btn-position" onClick={() => changeTime(null)}>&times;</button>
        </div>
    );
});

export default DateTimePicker;
