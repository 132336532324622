import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Customer from '../../models/Customer';

type ComponentParams = {
    userId: string,
    value: Customer | null,
    onChange: Function,
    stores?: any,
    operator: Operator | null
}

const CustomerSelect = ({ userId, value, onChange, stores, operator }: ComponentParams) => {
    const { t } = useTranslation();
    const [isDisabled, setDisabled] = useState(true);

    const loadOptions = async (userId): Promise<Customer[]> => { // TODO: same implementation in another component, need to find more elegant approach
        setDisabled(true);
        onChange(null); // reset current value to `null` before loading new options

        if (!userId || userId === '' || !operator) {
            return [];
        }

        const options = await stores.riderStore.getCustomers(userId, operator.id);

        setDisabled(options.length <= 1);

        if (options.length === 1) {
            onChange(options[0]);
        } else {
            onChange((value && options.find(i => i.id === value.id)) || null);
        }

        return options;
    };

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('page.order.attributesStartSearch')
            : t('page.order.attributesNoOptions', { q: inputValue });
    };

    return (
        <AsyncSelect
            key={userId}
            isDisabled={isDisabled}
            defaultOptions={true}
            isSearchable={false}
            blurInputOnSelect={true}
            closeMenuOnSelect={true}
            placeholder={t('page.order.attributesPlaceholder')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={() => loadOptions(userId)}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsMessage={setNoOptionsMessage}
            onChange={onChange}
        />
    );
};

export default inject('stores')(observer(CustomerSelect));
