import { action } from 'mobx';
import RiderApi from './riderApi';
import confs from '../conf';
import Rider from '../models/Rider';
import Customer from '../models/Customer';

export default class RiderStore {
    riderApi: RiderApi;

    constructor() {
        this.riderApi = new RiderApi(confs.api.url);
    }

    @action
    async search(q: string, operator: string): Promise<Rider[]> {
        return await this.riderApi.search(q, operator);
    }

    @action
    async getCustomers(userId: string, operator: string): Promise<Customer[]> {
        return await this.riderApi.getCustomers(userId, operator);
    }

    @action
    async getCustomersByName(name: string, operator: string): Promise<Customer[]> {
        return await this.riderApi.getCustomersByName(name, operator);
    }
}
