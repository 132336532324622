import React from 'react';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

type ComponentParams = {
    value: ExternalCompanyFavoriteAddress[] | undefined,
    onChange: Function,
}

const FavAddressSelect = ({value, onChange}: ComponentParams) => {
    const { t } = useTranslation();

    const setNoOptionsMessage = ({inputValue}) => {
        return inputValue === ''
            ? t('page.order.attributesStartSearch')
            : t('page.order.attributesNoOptions', { q: inputValue });
    };

    return (
        value && value.length > 0 ?
        <div>
            <label>{t('page.order.favAddresses')}</label>
            <Select
                isMulti={false}
                isSearchable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                placeholder={t('page.order.favAddressPlaceholder')}
                classNamePrefix="react-select"
                options={value}
                getOptionLabel={o => o.alias}
                getOptionValue={o => o.id}
                noOptionsMessage={setNoOptionsMessage}
                onChange={onChange}
            />
        </div> :
        <div/>
    );
};

export default observer(FavAddressSelect);
