import React from 'react'
import { Marker } from 'react-leaflet';
import Location from '../../models/Location';
import {observer} from 'mobx-react';

type ComponentParams = {
    location: Location,
    centerMap: Function,
}

const VinkaMapMarker = ({location, centerMap}: ComponentParams) => {

    const markerMoved = (e) => {
        // `move` event is fired both when the marker is dragged AND when the coordinates are set programmatically.
        // The only way to differ between them is to check the `originalEvent` property and ignore the move event in this case.
        if (e.type === 'move' && e.hasOwnProperty('originalEvent')) {
            return;
        }

        // if somehow one of the coordinates is not a number - do nothing
        const {lat, lng} = e.target.getLatLng();
        if (!isFinite(lat) || !isFinite(lng)) {
            return;
        }
        
        if (e.type === 'moveend') {
            location.setCoordinates(lat, lng);
            //centerMap(); // notify the map about changes

            const geocoder = new window['google'].maps.Geocoder();
            geocoder.geocode({'location': {lat, lng}}, results => {
                if (results && results.length > 0) {
                    // we already have coordinates defined by user dragging - keep them by passing a `preserveCoordinates` flag
                    location.fromGooglePlace(results[0], undefined, true);
                } else {
                    location.clear();
                }
            });

            return;
        }

        if (!e.oldLatLng || !e.latlng || JSON.stringify(e.oldLatLng) !== JSON.stringify(e.latlng)) {
            centerMap(); // notify the map about changes
        }

    };

    return location.hasCoordinates() ? (
        <Marker draggable type={'marker'}
                position={[location.lat, location.lng]}
                onAdd={markerMoved}
                onMove={markerMoved}
                onMoveend={markerMoved}
        />
    ) : (
        <></>
    );
};

export default observer(VinkaMapMarker);
