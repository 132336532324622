import UiStore from './uiStore';
import OrderStore from './orderStore';
import RiderStore from './riderStore';
import SocketService from './SocketService';
import MessageStore from './messageStore';

const orderStore = new OrderStore();
const riderStore = new RiderStore();
const uiStore = new UiStore();
const socket = new SocketService();
const messageStore = new MessageStore();

socket.addListener('order.confirm.ok', orderStore.confirmOkListener.bind(orderStore));
socket.addListener('order.confirm.failed', orderStore.confirmFailedListener.bind(orderStore));

export default { orderStore, riderStore, uiStore, socket, messageStore }
