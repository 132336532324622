import React from 'react';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce-promise';
import Rider from '../../models/Rider';
import { inject, observer } from 'mobx-react';

const Q_DEBOUNCE = 300;

type ComponentParams = {
    value: Rider | null,
    onChange: Function,
    stores?: any,
    operator: Operator | null
}

const isPhone = (s: string): boolean => {
    // from here https://en.wikipedia.org/wiki/Telephone_numbers_in_Finland
    const finnishPattern = /^((90[0-9]{3})?0|\+?358\s?)?(?!(100|20(0|2(0|[2-3])|9[8-9])|300|600|700|708|75(00[0-3]|(1|2)\d{2}|30[0-2]|32[0-2]|75[0-2]|98[0-2])))(4|50|10[1-9]|20(1|2(1|[4-9])|[3-9])|29|30[1-9]|71|73|75(00[3-9]|30[3-9]|32[3-9]|53[3-9]|83[3-9])|2|3|5|6|8|9|1[3-9])\s?(\d\s?){4,19}\d$/;

    return finnishPattern.test(s);
};

const isEmail = (s: string): boolean => {
    return /^[^@]+@[^@]+\.[^@.]+$/.test(s);
};

const prepareQ = (q: string): string => {
    if (q.includes('@')) {
        return isEmail(q) ? q : '';
    }

    if (q.match(/[a-z]/)) {
        return q;
    }

    q = q.replace(/\D/g, ''); // strip all non-numeric characters

    if (q.startsWith('0')) { // remove trailing zero
        q = q.slice(1)
    }

    if (!q.startsWith('358')) { // add trailing `358` if missing
        q = '358' + q;
    }

    return isPhone(q) ? q : '';
};

const RiderInput = ({ value, onChange, stores, operator }: ComponentParams) => {
    const { t } = useTranslation();

    const loadOptions = async (q: string): Promise<Rider[]> => {
        q = prepareQ(q);
        if (q.length < 2 || !operator) {
            return [];
        }

        return stores.riderStore.search(q, operator.id);
    };

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const formatOptionLabel = (option: Rider, { inputValue }: { inputValue: string }) => (
        <Highlighter
            searchWords={[prepareQ(inputValue)]}
            textToHighlight={`${option.name}, ${option.phone}, ${option.email}`}
        />
    );

    const setNoOptionsMessage = ({ inputValue }: { inputValue: string }): string => {
        return inputValue === ''
            ? t('page.order.riderStartSearch')
            : t('page.order.riderNoOptions', { q: inputValue });
    };

    const isOptionSelected = (o: Rider, selectedOptions: Rider[]): boolean => {
        return selectedOptions.length > 0 && selectedOptions[0].id === o.id;
    };

    return (
        <AsyncSelect
            blurInputOnSelect={true}
            placeholder={t('page.order.riderPlaceholder')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={debouncedLoadOptions}
            formatOptionLabel={formatOptionLabel}
            //getOptionLabel={option => `${option.name}, ${option.phone}, ${option.email}`} //option.name
            noOptionsMessage={setNoOptionsMessage}
            isOptionSelected={isOptionSelected}
            onChange={onChange}
        />
    );
};

export default inject('stores')(observer(RiderInput));
