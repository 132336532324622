import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import debounce from 'debounce-promise';
import { inject, observer } from 'mobx-react';
import Order from '../../models/Order';
import Location from '../../models/Location';
import DateRangePicker from '../DateRangePicker';
import moment from 'moment';

const Q_DEBOUNCE = 300;

type ComponentParams = {
    onChange: Function,
    stores?: any,
}

let inputValue = '';

const SearchPanel = ({ onChange, stores }: ComponentParams) => {

    const { i18n, t } = useTranslation();

    const [isDisabled, setIsDisabled] = useState(false);

    const loadOptions = async (q: string): Promise<Order[]> => {
        return q.length < 2 ? [] : await stores.orderStore.search(q, dateRange[0], dateRange[1]);
    };

    const [dateRange, setDateRange] = useState([moment().startOf('day').toDate(), moment().endOf('day').toDate()]);

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const displayText = (option) => {
        const date = moment(option.waypoints[0].requestedTime).locale(i18n.language).format('L LT');

        const rider = Order.findRider(option);

        const pickAddress = Location.getDisplayedAddress(option.waypoints[0].location);
        const dropAddress = Location.getDisplayedAddress((option.waypoints[1] || {}).location || {});

        let text = `${rider ? rider.name : '???'}, ${t('page.order.date')}: ${date}, ${t('page.order.pick').toLowerCase()}: ${pickAddress},
        ${t('page.order.drop').toLowerCase()}: ${dropAddress}`;

        const value = inputValue.toLowerCase();

        if (rider && rider.phone.toLowerCase().match(value)) {
            text += `, ${t('page.order.phone')}: ${rider.phone}`;
        }
        if (option.vehicle && option.vehicle.id && option.vehicle.id.match(value)) {
            text += `, ${t('page.order.vehicleNumber')}: ${option.vehicle.id}`;
        }
        if (option.id.toLowerCase().match(value)) {
            text += `, ${t('page.order.id').toLowerCase()}: ${option.id}`
        }
        if (option.sutiId && option.sutiId.match(value)) {
            text += `, ${t('page.order.sutiId').toLowerCase()}: ${option.sutiId}`
        }

        text += `, ${t('page.order.operator').toLowerCase()}: ${option.operator.name}`;

        return text;
    };

    const formatOptionLabel = (option: any, { inputValue }: { inputValue: string }) => (
        <Highlighter
            searchWords={[inputValue]}
            textToHighlight={displayText(option)}
        />
    );

    const onInputChange = (newInputValue) => {
        if (newInputValue !== '') {
            inputValue = newInputValue;
        }
    };

    const setNoOptionsMessage = ({ inputValue }: { inputValue: string }): string => {
        return inputValue === ''
            ? t('page.order.riderStartSearch')
            : t('page.order.orderNoOptions', { q: inputValue });
    };

    const onDateChange = (e: Date[]) => {
        if (e[0] === dateRange[0] && e[1] === dateRange[1]) return;
        e[0] > e[1] ? setIsDisabled(true) : setIsDisabled(false);
        setDateRange(e);
    };

    return (
        <div className="form-group">
            <div className="form-row">
                <div className="col-md-12">
                    <h5 className="card-title">{t('page.order.searchOrder')}</h5>
                    <DateRangePicker value={dateRange} onChange={onDateChange} />
                    <AsyncSelect
                        blurInputOnSelect={true}
                        placeholder={t('page.order.customerStartSearch')}
                        classNamePrefix="react-select"
                        loadOptions={debouncedLoadOptions}
                        formatOptionLabel={formatOptionLabel}
                        noOptionsMessage={setNoOptionsMessage}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        value={inputValue} //Now it clears the input field after order is selected. Should to save query string
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        </div>
    )
};

export default inject('stores')(observer(SearchPanel));

