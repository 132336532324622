export default class Customer {
    id: string = '';
    name: string = '';
    paymentInstrumentId = '';
    externalId: string = '';
    costCenter: string = '';
    email?: string;
    phone?: string;
    discount?: ExternalCompanyDiscount;
    favoriteAddresses?: ExternalCompanyFavoriteAddress[];
    useStaticAddresses: boolean = false;
    defaultCarAttributes?: IAttribute[];

    constructor(external?: ExternalCompanyUser, addresses?: ExternalCompanyFavoriteAddress[]) {
        if (external) {
            this.fromExternal(external);
            this.favoriteAddresses = addresses;
        }
    }

    fromExternal(external: ExternalCompanyUser) {
        this.id = external.customer.id;
        this.paymentInstrumentId = external.paymentInstrumentId;
        this.externalId = external.customer.externalId;
        this.costCenter = external.costCenter;
        this.name = external.customer.name;
        this.discount = external.customer.discounts[0]
        this.useStaticAddresses = external.customer.useStaticAddresses;
        this.defaultCarAttributes = external.customer.defaultCarAttributes;
    }

    fromApiFormat(apiCustomer: ICustomer) {
        this.id = apiCustomer.id || '';
        this.externalId = apiCustomer.externalId || '';
        this.name = apiCustomer.name || '';
        this.phone = apiCustomer.phone || undefined;
        this.email = apiCustomer.email || undefined;
    }
}
