import './messagePanel.css';

import React, {useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';

type ComponentParams = {
    stores?: any,
}

var messageCount = 0;

const MessagePanel = ({stores}: ComponentParams) => {

    const scrollTo = useRef<HTMLDivElement>(null);


    const messages = stores.messageStore.messages.map(i => (
        <div role="alert"
             key={i.id}
             className={`alert alert-${i.type}`}
             onClick={() => stores.messageStore.removeMessage(i)}
        >{i.text}</div>
    ));

    useEffect(() => {
        if (messages.length > messageCount){
            scrollTo.current?.scrollIntoView({behavior : "smooth"})
        }
        messageCount = messages.length;
    }, [messages]);

    return (
        <div className="messagePanel">
            {messages}
            <div ref={scrollTo} />
        </div>
    );
};

export default inject('stores')(observer(MessagePanel));
