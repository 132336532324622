import React, { ChangeEvent, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RiderInput from '../RiderInput';
import AddressInput from '../AddressInput';
import AttributeSelect from '../AttributeSelect';
import Modal, { useModal } from '../Modal';
import Order from '../../models/Order';
import DateTimePicker from '../DateTimePicker';
import CustomerSelect from '../CustomerSelect/CustomerSelect';
import CustomerInput from '../CustomerInput/CustomerInput';
import useForm from 'react-hook-form';
import Location from '../../models/Location';
import Rider from '../../models/Rider';
import Customer from '../../models/Customer';
import Payment from '../../models/Payment';
import InputError from '../InputError';
import SearchPanel from '../SearchPanel';
import OperatorSelect from '../OperatorSelect';
import PaymentTypeSelect from '../PaymentTypeSelect';
import DiscountInput from '../DiscountInput';
import moment from 'moment';
import ConfirmModal, { useConfirmModal } from "../ConfirmModal/ConfirmModal";

type ComponentParams = {
    stores?: any,
}

const validateLocationStreet = (location: Location) => location.street !== '';
const validateLocationCoordinates = (location: Location) => location.hasCoordinates();

const SAVED = 'saved',
    OK = 'ok',
    CONFIRMING = 'confirming',
    CONFIRMED = 'confirmed',
    ON_BOARD = 'on-board',
    FINISHED = 'finished',
    REJECTED = 'rejected',
    DISPATCH_FAILED = 'dispatch-failed',
    NO_SHOW = 'no-show',
    RIDER_CANCEL_FAILED = 'rider-cancel-failed',
    FAILED = 'failed',
    RIDER_CANCELLING = 'rider-cancelling';

const modalStatusClass = (status) => {
    if ([OK, SAVED, CONFIRMING, CONFIRMED, FINISHED, ON_BOARD].includes(status)) {
        return 'text-success';
    }
    if ([FAILED, REJECTED, DISPATCH_FAILED, RIDER_CANCEL_FAILED, NO_SHOW].includes(status)) {
        return 'text-danger';
    }
    return 'text-warning';
};

const OrderForm = ({ stores }: ComponentParams) => {
    const { t, i18n } = useTranslation();
    const { register, errors, setValue, handleSubmit, reset } = useForm();
    const { isShowingModal, toggleModal } = useModal();
    const { isShowingConfirmModal, toggleConfirmModal } = useConfirmModal();

    let order: Order = stores.orderStore.newOrder;

    let calendarRef: any = React.createRef();

    const [isSelectedOrder, setIsSelectedOrder] = useState(false);
    const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [isCompanySearchable, setIsCompanySearchable] = useState(true);
    const [isTemporaryRider, setIsTemporaryRider] = useState(false);

    const onChange = (e) => {
        order[e.target.name] = e.target.value;
    };

    const onChangeTemporaryRider = (e) => {
        order.temporaryRider = e.target.value;
        setValue('rider', e, true);
    };

    function useTemporaryRider() {
        setIsTemporaryRider(!isTemporaryRider);
    }

    const resetForm = (/*e*/) => {
        toggleModal(false);
        reset();
        setValue('rider', null);
        stores.orderStore.resetNewOrder();
        setIsTemporaryRider(false);
        setIsCompanySearchable(true);
        setIsSelectedOrder(false);
        setIsOrderConfirmed(false);
    };

    const copyBooking = (isReversed: boolean) => {
        toggleModal(false);
        const copiedOrder = isReversed ? order.clone().reverse() : order.clone();
        stores.orderStore.setNewOrder(copiedOrder);
        calendarRef.current.flatpickr.input.focus();
        setIsSelectedOrder(false);
        setIsOrderConfirmed(false);
    };

    const submitForm = async (/*e*/) => {
        if (order.requestedTime < new Date()) {
            order.requestedTime = new Date();
        }
        setButtonsDisabled(true);

        try {
            if (isTemporaryRider) {
                var rider = new Rider();
                rider.name = order.temporaryRider;
                order.rider = rider;
            }
            const response = await stores.orderStore.add(order);

            if (response) {
                order.updateFromResponse(response);
                toggleModal(true);
            }
        } catch (e) {
            stores.messageStore.addMessage({ type: 'danger', text: e.message });
        }

        setButtonsDisabled(false);
    };

    const confirmOrder = async () => {
        setButtonsDisabled(true);

        try {
            await stores.orderStore.confirm(order.id);
            setIsOrderConfirmed(true);
            toggleModal(true);
        } catch (e) {
            stores.messageStore.addMessage({ type: 'danger', text: e.message });
        }

        setButtonsDisabled(false);
    };

    const cancelOrder = async () => {
        try {
            const response = await stores.orderStore.delete(order.id);

            if (response) {
                order.status = response.status;
            }
        } catch (e) {
            stores.messageStore.addMessage({ type: 'danger', text: e.message });
        }

        toggleConfirmModal(false);
    };

    const copyOrderToModify = async () => {
        setButtonsDisabled(true);

        try {
            await stores.orderStore.delete(order.id);

            toggleModal(false);
            const copiedOrder = order.clone();
            copiedOrder.requestedTime = order.requestedTime;
            stores.orderStore.setNewOrder(copiedOrder);
            setIsOrderConfirmed(false);
        } catch (e) {
            stores.messageStore.addMessage({ type: 'danger', text: e.message });
        }

        setButtonsDisabled(false);
    };

    const addressHandler = (e: Location) => {

        setValue(e.type, e, !isNaN(e.lat) && !isNaN(e.lng));
        if (e.driverInfo) {
            /* CUSTOMER WANTED REPLACING DRIVER INFO INSTEAD OF APPENDING IT
            JUST UNCOMMENT THIS WHEN THEY WANT THIS CHANGE REVERSED
            var newInfo = [order.driverInfo, e.driverInfo].filter(Boolean).join(" ");
            e.driverInfo = "";
            order.driverInfo = newInfo;
            */
            order.driverInfo = e.driverInfo;
            e.driverInfo = "";
        }
    };

    const newAddressHandler = () => {
        order.addLocationAt();
    }

    const removeAddressHandler = (index: number) => {
        order.removeLocationAt(index);
    }

    const addressTypeHandler = (address: Location, type: string) => {
        address.type = type;
    }

    const orderHandler = async (apiOrder: IOrder) => {
        toggleModal(true);
        setIsSelectedOrder(true);

        // api rider might miss the id, lets try to prefetch it in order to avoid some issues in future
        const rider = Order.findRider(apiOrder);
        if (rider && !rider.id) {
            const fetchedRider = (await stores.riderStore.search(rider.phone, order.operator)).find(r => r.name === rider.name);

            if (fetchedRider) {
                apiOrder.waypoints.filter(i => i.rider).forEach(i => i.rider.id = fetchedRider.id);
            }
        }

        const newOrder = new Order();
        newOrder.fromApiFormat(apiOrder);

        stores.orderStore.setNewOrder(newOrder);
        setIsTemporaryRider(false);
        setIsCompanySearchable(true);
        setValue('rider', newOrder.rider);
    };

    const riderHandler = (e: Rider | null) => {
        if (order.rider !== e) {
            order.setRider(e);
            setIsCompanySearchable(false);
        }
        setValue('rider', e, true);
    };

    const customerHandler = (e: Customer | null) => {
        if (order.customer !== e) {
            order.setCustomer(e);
        }
        setValue('customer', e, e !== null);
    };

    const operatorHandler = (e: Operator) => {
        order.setOperator(e);
    }

    const paymentTypeHandler = (e: PaymentType) => {
        order.setPaymentType(e.id);
    }

    const discountHandler = (e: ExternalCompanyDiscount) => {
        order.setDiscount(e);
    }

    const paymentDescriptionHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (order.payment === null) {
            order.setPayment(new Payment());
        }

        order.payment!.description = e.target.value;
    }

    const riderSurrogateHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (order.rider !== null) {
            order.rider.surrogateName = e.target.value;
        }
    }

    const requestedTimeHandle = (e: Date) => {
        if (e && new Date(e.getTime() - 1000) < order.requestedTime) {
            return;
        }
        order.requestedTime = e;
    }

    useEffect(() => {
        register({ name: 'rider' }, { required: true });
        register({ name: 'customer' }, { required: true });
        register({ name: 'pick' }, { required: true, validate: { coordinates: validateLocationCoordinates, street: validateLocationStreet } });
        register({ name: 'drop' }, { required: true, validate: { coordinates: validateLocationCoordinates, street: validateLocationStreet } });
    });

    // watch for changes in addresses from outside
    // eslint-disable-next-line
    useEffect(() => order.locations.forEach(addressHandler), [order.locations.length, order.locations.map(i => i.street)]);

    const changeDoor = (e, location) => {
        location.door = e.target.value;
    };

    const addressInputs = order.locations.map((v, i) => (
        <div className="form-group" key={i}>
            <div className="form-row">
                <div className={([0, order.locations.length - 1].includes(i)) ? "col-md-10" : "col-md-9"}>
                    {[0, order.locations.length - 1].includes(i) ?
                        <label>{t(`page.order.${v.type}`)}</label> :
                        <label>
                            <select
                                className="select-text"
                                value={v.type}
                                onChange={(e) => addressTypeHandler(v, e.target.value)}
                            >
                                <option value="checkpoint" >
                                    {t(`page.order.checkpoint`)}
                                </option>
                                <option value="pick">
                                    {t(`page.order.pick`)}
                                </option>
                                <option value="drop">
                                    {t(`page.order.drop`)}
                                </option>
                            </select>
                        </label>
                    }
                    <AddressInput useStaticAddresses={order.customer?.useStaticAddresses} favAddresses={order.customer?.favoriteAddresses} location={v} onChanged={addressHandler} />
                    <InputError name={v.type} error={errors[v.type]} />
                </div>
                <div className="col-md-2">
                    <label>{t('page.order.door')}</label>
                    <input value={v.door} onChange={(e) => changeDoor(e, v)} className="form-control" maxLength={10} />
                </div>
                {!([0, order.locations.length - 1].includes(i)) &&
                    <div className="col-md-1 mt-2">
                        <button type="button" className="btn btn-danger px-3 address-delete-btn" onClick={() => removeAddressHandler(i)}>X</button>
                    </div>
                }
            </div>
            {i === order.locations.length - 2 &&
                <div className="form-row mt-2" style={{ marginBottom: '-10px' }}>
                    <div className="col">
                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={newAddressHandler} disabled={buttonsDisabled}>{t('page.order.addAddress')}</button>
                    </div>
                </div>
            }
        </div>
    ));

    const modalAddresses = order.locations.map((v, i) => (
        <div className="form-group" key={i}>
            <div className="font-weight-bold">{t(`page.order.${v.type}`)}</div>
            {v.formattedAddress}
        </div>
    ));

    const modalButtons = () => {
        if (isSelectedOrder && order.status === RIDER_CANCELLING) {
            return (
                <div className="form-group">
                    <button type="button" className="btn btn-outline-dark float-right" onClick={resetForm}>{t('button.close')}</button>

                    <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={() => copyBooking(false)}>{t('button.copyNew')}</button>
                </div>
            )
        }
        if (isSelectedOrder) {
            return (
                <div className="form-group">
                    <button type="button" className="btn btn-outline-dark float-right" onClick={resetForm} disabled={buttonsDisabled}>{t('button.close')}</button>

                    <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={() => toggleConfirmModal(true)} disabled={buttonsDisabled}>{t('button.cancel')}</button>

                    <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={() => copyBooking(false)} disabled={buttonsDisabled}>{t('button.copyNew')}</button>
                </div>
            )
        }
        if (isOrderConfirmed) {
            return (
                <div className="form-group">
                    <button type="button" className="btn btn-outline-dark float-right" onClick={resetForm}>{t('button.newOrder')}</button>

                    <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={() => copyBooking(true)}>{t('button.addReturn')}</button>

                    <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={() => copyBooking(false)}>{t('button.copyNew')}</button>
                </div>
            )
        }
        return (
            <div className="form-group">
                <button type="button" className="btn btn-outline-dark float-right" onClick={confirmOrder} disabled={buttonsDisabled}>{t('button.confirm')}</button>

                <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={copyOrderToModify} disabled={buttonsDisabled}>{t('button.modify')}</button>

                <button type="button" className="btn btn-outline-dark float-right mr-2" onClick={resetForm} disabled={buttonsDisabled}>{t('button.cancel')}</button>
            </div>
        )
    };

    const companyInputs = () => {
        if (isCompanySearchable) {
            return (
                <div className="form-group">
                    <div className="form-row">
                        <div className="col-md-6">
                            <label htmlFor="customer"> {t('page.order.customer')}</label>
                            <CustomerInput userId={(order.rider ? order.rider.id : '')} value={order.customer} onChange={customerHandler} operator={order.operator} />
                            <InputError name="customer" error={errors.customer} />
                        </div>
                        <div className="col-md-6">
                            <InputError name="costCenter" error={errors.costCenter} />
                            <label htmlFor="costCenter">{t('page.order.costCenter')}</label>
                            <input className="form-control" id="costCenter" name="costCenter" value={order.costCenter} onChange={onChange} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="form-group">
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="customer"> {t('page.order.customer')}</label>
                        <CustomerSelect userId={(order.rider ? order.rider.id : '')} value={order.customer} onChange={customerHandler} operator={order.operator} />
                        <InputError name="customer" error={errors.customer} />
                    </div>
                    <div className="col-md-6">
                        <InputError name="costCenter" error={errors.costCenter} />
                        <label htmlFor="costCenter">{t('page.order.costCenter')}</label>
                        <input className="form-control" id="costCenter" name="costCenter" value={order.costCenter} onChange={onChange} />
                    </div>
                </div>
            </div>
        );

    }

    const riderInputs = () => {
        if (isTemporaryRider) {
            return (
                <div className="form-group">
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="rider">{t('page.order.rider')}</label>
                            <input className="form-control" id="temporaryRider" name="temporaryRider" value={order.temporaryRider} onChange={onChangeTemporaryRider} />
                            <InputError name="rider" error={errors.rider} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="form-group">
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="rider">{t('page.order.rider')}</label>
                        <RiderInput value={order.rider} onChange={riderHandler} operator={order.operator} />
                        <InputError name="rider" error={errors.rider} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="vinka-containers">
            <div className="side-panel card card-inverse">
                <div>
                    <h5 className="card-title">{t('page.order.title')}</h5>

                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="operator">{t('page.operator')}</label>
                                <OperatorSelect value={order.operator} onChange={operatorHandler} />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="temporaryRider">{t('page.order.temporaryRider')}
                                <input
                                    id="temporaryRider"
                                    type="checkbox"
                                    checked={isTemporaryRider}
                                    onChange={useTemporaryRider}
                                />
                            </label>
                        </div>
                    </div>
                    {riderInputs()}

                    {order.rider && (
                        <div className="form-group">
                            <label htmlFor="riderSurrogate">{t('page.order.riderSurrogate')}</label>
                            <textarea
                                className="form-control"
                                id="riderSurrogate"
                                name="riderSurrogate"
                                value={order.rider.surrogateName}
                                onChange={riderSurrogateHandler}
                            />
                        </div>
                    )}
                    {companyInputs()}
                    <div className="form-group">
                        <label htmlFor="projectCode">{t('page.order.projectCode')}</label>
                        <input className="form-control" id="projectCode" name="projectCode" value={order.projectCode} onChange={onChange} />
                    </div>

                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-md-12">
                                <label>{t('page.order.attributes')}</label>
                                <AttributeSelect value={order.attributes} onChange={e => order.attributes = e} />
                            </div>
                        </div>
                    </div>

                    {addressInputs}

                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label>{t('page.order.pickTime')}</label>
                                {<DateTimePicker
                                    value={order.requestedTime}
                                    onChange={e => requestedTimeHandle(e)}
                                    ref={calendarRef}
                                />}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-row">
                            <div className="col-md-12">
                                <label htmlFor="paymentType">{t('page.order.paymentType')}</label>
                                <PaymentTypeSelect value={{ id: order.payment.type }} onChange={paymentTypeHandler} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <DiscountInput value={order.payment.discount} onChange={discountHandler} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="dispatcherInfo">{t('page.order.dispatcherInfo')}</label>
                        <textarea className="form-control" id="dispatcherInfo" name="dispatcherInfo" value={order.dispatcherInfo} onChange={onChange} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="driverInfo">{t('page.order.driverInfo')}</label>
                        <textarea className="form-control" id="driverInfo" name="driverInfo" value={order.driverInfo} onChange={onChange} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="invoicingInfo">{t('page.order.invoicingInfo')}</label>
                        <textarea
                            className="form-control"
                            id="invoicingInfo"
                            name="invoicingInfo"
                            value={order.payment!.description}
                            onChange={paymentDescriptionHandler}
                        />
                    </div>

                    <div className="form-group">
                        <button type="button" className="btn btn-light float-right" onClick={handleSubmit(submitForm)} disabled={buttonsDisabled}>{t('button.send')}</button>

                        <button type="button" className="btn btn-outline-light" onClick={resetForm} disabled={buttonsDisabled}>{t('button.cancel')}</button>
                    </div>

                    <Modal
                        isShowing={isShowingModal}
                        hide={resetForm}
                    >
                        <h5 className="card-title">{t('page.order.orderSent')} - <span className={modalStatusClass(order.status)}>{t(`page.order.status.${order.status}`)}</span></h5>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.id')}</div>
                            {order.id}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.sutiId')}</div>
                            {order.sutiId}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.fleetId')} {order.vehicle ? `- ${t('page.order.vehicleNumber')}` : ''}</div>
                            {order.fleetId} {order.vehicle ? ` - ${order.vehicle.id}` : ''}
                        </div>

                        {(order.vehicle && order.vehicle.phone) && (
                            <div className="form-group">
                                <div className="font-weight-bold">{t('page.order.vehiclePhone')}</div>
                                {order.vehicle.phone}
                            </div>
                        )}

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.rider')}</div>
                            {order.rider ? order.rider.name : ''}
                        </div>

                        {order.rider && order.rider.surrogateName && (
                            <div className="form-group">
                                <div className="font-weight-bold">{t('page.order.riderSurrogate')}</div>
                                {order.rider.surrogateName}
                            </div>
                        )}

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.customer')}</div>
                            {order.customer ? order.customer.name : ''}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.costCenter')}</div>
                            {order.costCenter}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.projectCode')}</div>
                            {order.projectCode}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.attributes')}</div>
                            {(order.attributes || []).map(i => t(`attribute.${i.id}`)).join(', ')}
                        </div>

                        {modalAddresses}

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.pickTime')}</div>
                            {moment(order.requestedTime).locale(i18n.language).format('LLLL')}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.dispatcherInfo')}</div>
                            {order.dispatcherInfo}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.driverInfo')}</div>
                            {order.driverInfo}
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.price')}</div>
                            {`${order.price.toFixed(2)}`.split('.').join(',')} €
                        </div>

                        <div className="form-group">
                            <div className="font-weight-bold">{t('page.order.vat')}</div>
                            {`${order.tax.toFixed(2)}`.split('.').join(',')} € ({order.taxRate} %)
                        </div>

                        {order.fleetPrice && (
                            <div className="form-group">
                                <div className="font-weight-bold">{t('page.order.fleetPrice')}</div>
                                {`${order.fleetPrice.toFixed(2)}`.split('.').join(',')} €
                            </div>
                        )}

                        {order.operator && (
                            <div className="form-group">
                                <div className="font-weight-bold">{t('page.order.operator')}</div>
                                {order.operator.name}
                            </div>
                        )}

                        {order.payment && order.payment.description && (
                            <div className="form-group">
                                <div className="font-weight-bold">{t('page.order.invoicingInfo')}</div>
                                {order.payment.description}
                            </div>
                        )}

                        {modalButtons()}
                    </Modal>

                    <ConfirmModal message={t('confirmModal.orderCancelling')} isShowing={isShowingConfirmModal} onConfirm={cancelOrder} onReject={() => toggleConfirmModal(false)} />
                </div>
            </div>
            <div className="side-panel side-panel_search card">
                <SearchPanel onChange={orderHandler} />
            </div>
        </div>
    );
};

export default inject('stores')(observer(OrderForm));
