import React from 'react';
import { useTranslation } from 'react-i18next';

type ComponentParams = {
    name: any,
    error: any,
}

const InputError = ({name, error}: ComponentParams) => {
    const { t } = useTranslation();

    return error ? <div className="invalid-tooltip invalid-tooltip-inline">{t(`error.${name}.${error.type}`)}</div> : null;
};

export default InputError;
