import React, {useState} from 'react';
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";

export const useConfirmModal = () => {
    const [isShowingConfirmModal, setIsShowingConfirm] = useState(false);

    const toggleConfirmModal = (toggle:boolean|null = null) => {
        if (toggle === null) {
            setIsShowingConfirm(!isShowingConfirmModal);
        } else if (typeof toggle === 'boolean') {
            setIsShowingConfirm(toggle);
        }
    };

    return {
        isShowingConfirmModal,
        toggleConfirmModal
    }
};

type ComponentParams = {
    message: string,
    isShowing: boolean,
    onConfirm: Function,
    onReject: Function
}

const ConfirmModal = ({message, isShowing, onConfirm, onReject} : ComponentParams) => {
    const { t } = useTranslation();

    if (!isShowing) {
        return null
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="modal-overlay" />

            <div className="modal-wrapper confirm-modal-width">
                <h5 className="mb-3">{message}</h5>
                <button type="button" className="btn btn-warning float-left w-25" onClick={() => onConfirm()}>{t('confirmModal.yes')}</button>
                <button type="button" className="btn btn-dark float-right w-25" onClick={() => onReject()}>{t('confirmModal.no')}</button>
            </div>
        </React.Fragment>, document.body
    );
};

export default ConfirmModal;