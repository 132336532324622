import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const discountTypes = [
    { value: 'percentage' },
    { value: 'fixed' },
    { value: 'factor' }
];

let discount: ExternalCompanyDiscount = {
    type: 'percentage',
    description: '',
    value: 0
}

type ComponentParams = {
    value: ExternalCompanyDiscount | undefined,
    onChange: Function,
}

const DiscountSelect = ({ value, onChange }: ComponentParams) => {
    const { t } = useTranslation();

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('page.order.discountTypeStartSearch')
            : t('page.order.discountTypeNoOptions', { q: inputValue });
    };

    function updateType(e) {
        discount.type = e.value
        onChange(discount);
    }

    function updateValue(e) {
        discount.value = e.target.value;
        onChange(discount);
    }

    function updateDescription(e) {
        discount.description = e.target.value;
        onChange(discount);
    }

    return (
        <div className="form-row">
            <div className="col-md-5">
                <label htmlFor="discountType">{t('page.order.discountType')}</label>
                <Select
                    id="discountType"
                    closeMenuOnSelect={true}
                    placeholder={t('page.order.discountTypePlaceholder')}
                    classNamePrefix="react-select"
                    value={{ value: value?.type }}
                    options={discountTypes}
                    getOptionLabel={o => !o.value ? t('page.order.discountTypePlaceholder') : t('page.order.discountType' + o.value.charAt(0).toUpperCase() + o.value.slice(1))}
                    noOptionsMessage={setNoOptionsMessage}
                    onChange={updateType}
                />
            </div>
            <div className="col-md-2">
                <label htmlFor="discountValue">{t('page.order.discountValue')}</label>
                <input className="form-control" id="discountValue" value={value?.value || ''} onChange={updateValue} />
            </div>
            <div className="col-md-5">
                <label htmlFor="discountDescription">{t('page.order.discountDescription')}</label>
                <input className="form-control" id="discountDescription" value={value?.description || ''} onChange={updateDescription} />
            </div>
        </div>
    );
};

export default observer(DiscountSelect);
