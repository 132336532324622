import { MsalAuthProvider, LoginType } from 'react-aad-msal';

let authProvider: MsalAuthProvider;

// For adding logging to authprovider use:
/*
import { Logger, LogLevel } from 'msal';
system: {
    logger: new Logger(
        function() {console.log(arguments);} ,{
                level: LogLevel.Verbose,
                piiLoggingEnabled: false,
                correlationId: '1234'
        }
    ),
}
*/

export const scopes = {
    allAccess: 'api://5c913c26-501b-47cb-bdca-144625af68a4/Access.all',
};

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
// * Note: *
// calling getAccessToken() in API classes requires that scope is given as parameter for the function
// otherwise it cannot read saved token from cache, will fetch it from azure and make page reload as
// azure auth returns 302 http response.
// use e.g: getAccessToken({scopes: [scopes.allAccess]})
export function getAuthProvider(): MsalAuthProvider {
    if (!authProvider) {
        console.log('creating new auth provider');
        authProvider = new MsalAuthProvider(
            {
                auth: {
                    authority: process.env.REACT_APP_AUTHORITY,
                    clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID!,
                    postLogoutRedirectUri: window.location.origin,
                    redirectUri: window.location.origin,
                    validateAuthority: true,

                    // After being redirected to the "redirectUri" page, should user
                    // be redirected back to the Url where their login originated from?
                    navigateToLoginRequestUrl: false,
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true,
                },
            },
            {
                scopes: ['openid', scopes.allAccess],
            },
            LoginType.Popup,
        );
    }
    return authProvider;
}
