import { action, observable } from 'mobx';
import { getI18n } from 'react-i18next';

export default class Location implements ILocation {
    static PICK = 'pick';
    static DROP = 'drop';

    @observable street: string = '';
    @observable streetNumber: string = '';
    @observable door: string = '';
    @observable city: string = '';
    @observable zipcode: string = '';
    @observable country: string = '';
    @observable alias: string = '';
    @observable lat: number = NaN;
    @observable lng: number = NaN;
    @observable info: string = '';
    @observable type: string = '';
    @observable formattedAddress: string = '';
    @observable driverInfo: string = '';

    constructor(type: string) {
        this.type = type;
    }

    clear() {
        this.street = '';
        this.streetNumber = '';
        this.door = '';
        this.city = '';
        this.zipcode = '';
        this.country = '';
        this.alias = '';
        this.lat = NaN;
        this.lng = NaN;
        this.info = '';
        this.formattedAddress = '';
        this.driverInfo = '';
    }

    hasCoordinates() {
        return isFinite(this.lat) && isFinite(this.lng);
    }

    @action
    setCoordinates(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }

    @action
    fromGooglePlace(googlePlace: any/*GeocoderResult*/, address: string = '', preserveCoordinates: boolean = false) {
        if (!preserveCoordinates) {
            this.lat = googlePlace.geometry.location.lat();
            this.lng = googlePlace.geometry.location.lng();
        }

        const tmp = googlePlace.address_components.reduce((a, i) => Object.assign(a, { [i['types'][0]]: i['long_name'] }), {});
        this.street = tmp['route'] || '';
        this.streetNumber = tmp['street_number'] || '';
        this.city = tmp['locality'] || tmp['administrative_area_level_3'] || '';
        this.zipcode = tmp['postal_code'] || '';
        this.country = tmp['country'] || '';

        const alias = address.split(',');
        if (alias[0] !== '' && alias[0] !== `${this.street} ${this.streetNumber}`) {
            this.alias = alias[0];
        } else {
            this.alias = '';
        }

        this.formattedAddress = Location.getDisplayedAddress(this);
    }

    toApiFormat() {
        const apiFormat = Object.assign({}, this);

        //@ts-ignore
        delete apiFormat.type;

        //@ts-ignore
        delete apiFormat.driverInfo;

        //@ts-ignore
        delete apiFormat.formattedAddress;

        return apiFormat;
    }

    fromApiFormat(apiLocation: ILocation) {
        this.street = apiLocation.street || '';
        this.streetNumber = apiLocation.streetNumber || '';
        this.door = apiLocation.door || '';
        this.city = apiLocation.city || '';
        this.zipcode = apiLocation.zipcode || '';
        this.country = apiLocation.country || '';
        this.alias = apiLocation.alias || '';
        this.lat = apiLocation.lat || NaN;
        this.lng = apiLocation.lng || NaN;
        this.info = apiLocation.info || '';
        this.formattedAddress = Location.getDisplayedAddress(this);
    }

    static getDisplayedAddress(address) {
        return [
            address.alias ? `"${address.alias}"` : '',
            [address.street || getI18n().t('page.order.unknownStreet'), address.streetNumber].filter(i => Boolean(i)).join(' '),
            address.door,
            [address.zipcode, address.city].filter(i => Boolean(i)).join(' '),
            address.country
        ].filter(i => Boolean(i)).join(', ');
    }
}
