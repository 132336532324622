import 'flatpickr/dist/flatpickr.css'; // TODO: should we move it to the `App.jsx` or `index.jsx`? Or leave here?
import { Finnish } from 'flatpickr/dist/l10n/fi.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import 'moment/locale/fi';

type ComponentParams = {
    value: Date[],
    onChange: Function
}

type DateRange = {
    name: string,
    startDate: Date,
    endDate: Date
}

const dateRanges: DateRange[] = [
    {
        name: 'today',
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate()
    },
    {
        name: 'lastWeek',
        startDate: moment().subtract(7, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate()
    },
    {
        name: 'nextWeek',
        startDate: moment().startOf('day').toDate(),
        endDate: moment().add(7, 'days').endOf('day').toDate()
    }
];

const DateRangePicker = (({ value, onChange }: ComponentParams) => {

    const { i18n, t } = useTranslation();
    const locale = i18n.language === 'fi' ? Finnish : null;

    const [isShow, setIsShow] = useState(false);
    const [dateRangeName, setRangeName] = useState('today');

    const invalid: boolean = value[0] > value[1];

    const changeDate = (e: Date[], isFirstDay: boolean) => {
        if ((isFirstDay && value[0] === e[0]) || (!isFirstDay && value[1] === e[1])) return;
        isFirstDay ? value[0] = e[0] : value[1] = e[0];
        setRangeName('' + e[0]);
        onChange(value);
    };

    const changeDateRange = (dateRange: DateRange) => {
        setRangeName(dateRange.name);
        onChange([dateRange.startDate, dateRange.endDate]);
    };

    let uiName = dateRanges.find(i => i.name === dateRangeName) ?
        t(`button.${dateRangeName}`) :
        value.map(i => moment(i).locale(i18n.language).format('L')).join(' - ');

    const buttons = dateRanges.map((dr, i) => (
        <button className="btn btn-outline-light mb-3 range-button-width" key={i} onClick={() => changeDateRange(dr)}>{t(`button.${dr.name}`)}</button>
    ));

    const errorMessage = (<p className="error-daterange-message">{t(`error.dateRange.invalid`)}</p>);

    return (
        <div className="form-row">
            <div className="col-md-12">
                <h6 className="date-range" onClick={() => setIsShow(!isShow)}>{uiName}</h6>
                {invalid && errorMessage}
                {isShow && (
                    <div className="form-row justify-content-between">
                        <div className="col-md-6">
                            <Flatpickr
                                key={locale}
                                className="form-control mb-3"
                                value={value[0]}
                                data-enable-time
                                onChange={(e) => changeDate(e, true)}
                                options={{
                                    locale: locale,
                                    formatDate: e => moment(e).locale(i18n.language).format('L LT')
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <Flatpickr
                                key={locale}
                                className="form-control mb-3"
                                value={value[1]}
                                data-enable-time
                                onChange={(e) => changeDate(e, false)}
                                options={{
                                    locale: locale,
                                    formatDate: e => moment(e).locale(i18n.language).format('L LT')
                                }}
                            />
                        </div>
                        <div className="col-md-12 d-flex justify-content-between">
                            {buttons}
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
});

export default DateRangePicker;
