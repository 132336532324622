import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const options = [
    { "id": 'taksipartners', "name": "Taksi Partners" },
    { "id": 'ltx', "name": "Lähitaksi" }
];

type ComponentParams = {
    value: Operator | null,
    onChange: Function,
}

const OperatorSelect = ({ value, onChange }: ComponentParams) => {
    const { t } = useTranslation();

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('page.order.operatorsStartSearch')
            : t('page.order.operatorsNoOptions', { q: inputValue });
    };

    return (
        <Select
            closeMenuOnSelect={true}
            placeholder={t('page.order.operatorsPlaceholder')}
            classNamePrefix="react-select"
            value={value}
            options={options}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsMessage={setNoOptionsMessage}
            onChange={onChange}
        />
    );
};

export default observer(OperatorSelect);
