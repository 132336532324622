import axios from 'axios';
import Rider from '../models/Rider';
import Customer from '../models/Customer';
import {getAuthProvider, scopes} from '../authProvider';

class RiderApi {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    async search(q: string, operator: string): Promise<Rider[]> {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});
        try{
            const response = await axios.get(
                `${this.url}/rider`,
                {
                    params: {q, operator: operator},
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`
                    }
                }
            );
            console.log(response.data);
            const extUsers: ExternalUser[] = response.data.data.results;
            return extUsers.map(u => new Rider(u));
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getCustomers(userId: string, operator: string):Promise<Customer[]> {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.get(
                `${this.url}/rider/${userId}/customer`,
                {
                    params: { operator: operator},
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,
                    },
                 // withCredentials: true
                }
            );
            const extCompanies: ExternalCompanyUser[] = response.data.data.results;

            let companies = await Promise.all(extCompanies.map(async c => 
                {
                    let addresses = await this.getFavoriteAddresses(c.customerId, operator);
                    return new Customer(c, addresses)
                }
            ));

            console.log(companies);

            return companies;
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getCustomersByName(name: string, operator: string): Promise<Customer[]> {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.get(
                `${this.url}/customer/search/${name}`,
                {
                    params: { operator: operator},
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,
                    },
                }
            );

            const extCompanies: ExternalCompany[] = response.data.data.results;

            let companies = await Promise.all(extCompanies.map(async c => 
                {
                    let customer: Customer = new Customer();

                    let addresses = await this.getFavoriteAddresses(c.id, operator);

                    customer.favoriteAddresses = addresses;
                    customer.id = c.id;
                    customer.name = c.name;
                    customer.externalId = c.externalId;
                    customer.useStaticAddresses = c.useStaticAddresses;
                    customer.defaultCarAttributes = c.defaultCarAttributes;

                    return customer;
                }
            ));

            console.log(companies);

            return companies;
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getFavoriteAddresses(customerId: string, operator: string):Promise<ExternalCompanyFavoriteAddress[]> {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.get(
                `${this.url}/customer/${customerId}/favoriteAddresses`,
                {
                    params: { operator: operator},
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,
                    },
                 // withCredentials: true
                }
            );

            const extCompanyFavoriteAddresses: ExternalCompanyFavoriteAddress[] = response.data.data.results;

            return extCompanyFavoriteAddresses;
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }
}

export default RiderApi;
