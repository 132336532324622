import axios from 'axios';
import Order from '../models/Order';
import {getAuthProvider, scopes} from '../authProvider';

class OrderApi {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    async add(order: Order) {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.post(
                `${this.url}/order`,
                order.toApiFormat(),
                { headers: { Authorization: `Bearer ${token.accessToken}` }}
            );

            return response.data.data;
        } catch (e) {
            console.log('fetch failed', e);
            throw e; //  continue to error messenger
        }
    }

    async search(q: string, startTime: Date, endTime: Date): Promise<Order[]> {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try{
            const response = await axios.get(
                `${this.url}/order/search`,
                {
                    params: {
                        q,
                        startTime: startTime.toISOString(),
                        endTime: endTime.toISOString()
                    },
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`
                    }
                }
            );

            return response.data.data;

        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async delete(id: string) {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.delete(
                `${this.url}/order/${id}`,
                { headers: { Authorization: `Bearer ${token.accessToken}` }}
            );

            return response.data.data;
        } catch (e) {
            console.log('fetch failed', e);
            throw e; //  continue to error messenger
        }
    }

    async confirm(id: string) {
        const token = await getAuthProvider().getAccessToken({scopes: [scopes.allAccess]});

        try {
            const response = await axios.put(
                `${this.url}/order/${id}/confirmed`,
                {data: {confirmed: true}},
                { headers: { Authorization: `Bearer ${token.accessToken}` }}
            );

            return response.data;
        } catch (e) {
            console.log('fetch failed', e);
            throw e; //  continue to error messenger
        }
    }
}

export default OrderApi;
