import React from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Customer from '../../models/Customer';
import debounce from 'debounce-promise';

const Q_DEBOUNCE = 300;

type ComponentParams = {
    userId: string,
    value: Customer | null,
    onChange: Function,
    stores?: any,
    operator: Operator | null
}

const CustomerInput= ({ userId, value, onChange, stores, operator }: ComponentParams) => {
    const { t } = useTranslation();

    const loadOptions = async (userId): Promise<Customer[]> => {
        if (!userId || userId === '' || !operator) {
            return [];
        }

        const options = await stores.riderStore.getCustomersByName(userId, operator.id);

        return options;
    };

    const debouncedLoadOptions = debounce(loadOptions, Q_DEBOUNCE);

    const setNoOptionsMessage = ({ inputValue }) => {
        return inputValue === ''
            ? t('page.order.attributesStartSearch')
            : t('page.order.attributesNoOptions', { q: inputValue });
    };

    return (
        <AsyncSelect
            key={userId}
            defaultOptions={true}
            isSearchable={true}
            blurInputOnSelect={true}
            closeMenuOnSelect={true}
            placeholder={t('page.order.attributesPlaceholder')}
            classNamePrefix="react-select"
            value={value}
            loadOptions={debouncedLoadOptions}
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            noOptionsMessage={setNoOptionsMessage}
            onChange={onChange}
        />
    );
};

export default inject('stores')(observer(CustomerInput));
