import { observable } from 'mobx';

export default class Rider {
    id: string = '';
    firstName: string = '';
    lastName: string = '';
    name: string = '';
    email: string = '';
    costCenter: string = '';
    phone: string = '';
    @observable surrogateName: string = '';

    constructor(external?: ExternalUser) {
        if (external) {
            this.fromExternal(external);
        }
    }

    fromExternal(external: ExternalUser) {
        const contactInfoProfile = external.profiles[0];

        this.id = external.id;
        this.name = contactInfoProfile.fullName;
        this.firstName = contactInfoProfile.firstName;
        this.lastName = contactInfoProfile.lastName;
        this.email = contactInfoProfile.email;
        this.phone = contactInfoProfile.phoneNumber;
    }

    toApiFormat() {
        return {
            name: this.name,
            phone: this.phone,
            surrogateName: this.surrogateName
        }
    }

    fromApiFormat(apiRider: IRider) {
        this.id = apiRider.id || '';
        this.name = apiRider.name || '';
        this.phone = apiRider.phone || '';
        this.surrogateName = apiRider.surrogateName || '';
    }
}
