import React, { createRef } from 'react'
import {LayerGroup, Map, TileLayer} from 'react-leaflet';
import {inject, observer} from 'mobx-react';
import L from 'leaflet';
import Location from '../../models/Location';
import VinkaMapMarker from '../VinkaMapMarker';
import 'leaflet/dist/leaflet.css';

let prot: any = L.Icon.Default.prototype;
delete prot._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

type MarkersListParams = {
    locations: Location[],
    centerMap: Function,
}

const MarkersList = observer(({ locations, centerMap }: MarkersListParams) => {
    const markers = locations.filter(i => i.lat !== null && i.lng !== null).map((v, i) => (
        <VinkaMapMarker key={i} location={v} centerMap={centerMap} />
    ));

    return <LayerGroup type={'markers'}>{markers}</LayerGroup>
});

type ComponentParams = {
    stores?: any,
}

const VinkaMap = ({stores}: ComponentParams) => {
    const mapRef = createRef<Map>();
    const lat = 60.16985569999999;
    const lng = 24.9383791;
    const zoom = 13;

    const locations: Location[] = stores.orderStore.newOrder.locations;

    const centerMap = () => {
        const map:any = mapRef.current;

        if (map !== null) {
            map.leafletElement.eachLayer(layer => {
                if (layer.options.type === 'markers') {
                    const points = layer.getLayers().map(i => i.getLatLng()).filter(i => Number.isFinite(i.lat) && Number.isFinite(i.lng));

                    if (points.length > 1) {
                        map.leafletElement.fitBounds(L.latLngBounds(points), {paddingTopLeft: [540, 40], paddingBottomRight: [40, 40]});
                    } else if (points.length === 1) {
                        map.leafletElement.fitBounds(points[0].toBounds(50), {paddingTopLeft: [540, 40], paddingBottomRight: [40, 40]});
                    }
                }
            });
        }
    };

    return (
        <Map ref={mapRef}
             center={[lat, lng]}
             zoom={zoom}
             style={{ width: '100%', height: '100%' }}
             zoomControl={false}
             maxZoom={19}
        >
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MarkersList locations={locations} centerMap={centerMap} />
        </Map>
    )
};

export default inject('stores')(observer(VinkaMap));
