import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import confs from '../../conf';
import Location from '../../models/Location';
import FavAddressSelect from '../FavAddressSelect';

type ComponentParams = {
    location: Location,
    favAddresses: ExternalCompanyFavoriteAddress[] | undefined,
    useStaticAddresses: boolean | undefined,
    onChanged: Function,
}

const AddressInput = ({ location, useStaticAddresses, favAddresses, onChanged }: ComponentParams) => {
    const { t } = useTranslation();

    const staticAddress = useStaticAddresses === true;
    const favAddressHandler = (e: ExternalCompanyFavoriteAddress) => {
        var address = e.street + " " +e.streetNumber + ", " + e.zipcode + " " + e.city + ", " + e.country;
        geocodeByAddress(address)
        .then(results => {
            location.fromGooglePlace(results[0], address);
            location.driverInfo = e.driverNotes;
            onChanged(location);
        })
        .catch(onError);
    }

    const onError = (e: any) => console.log(e);
    const onChange = (address: string) => location.formattedAddress = address;
    const onSelect = (address: string, placeId: string) => {
        if (!placeId || placeId === '') {
            return;
        }

        geocodeByPlaceId(placeId)
            .then(results => {
                location.fromGooglePlace(results[0], address);
                onChanged(location);
            })
            .catch(onError);
    };

    // TODO: improve the inner structure of the component, it is very messy at the moment... bring into the consistency with the `react-select` component
    return (
        <div>
        <PlacesAutocomplete
            highlightFirstSuggestion={true}
            searchOptions={{ componentRestrictions: { country: confs.google.country } }}
            value={location.formattedAddress}
            onChange={onChange}
            onSelect={onSelect}
            onError={onError}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                const showDropdown = loading || suggestions.length > 0;

                return (
                    <div>
                        <input
                            {...getInputProps({
                                placeholder: t('page.order.lookup'),
                                className: 'form-control',
                                readOnly: staticAddress
                            })}
                        />

                        {showDropdown && <div className="autocomplete-dropdown-container">
                            {loading && <div>{t('page.order.loading')}</div>}

                            <div className="autocomplete-dropdown-inner">
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active ? 'autocomplete-dropdown-option autocomplete-dropdown-option-active' : 'autocomplete-dropdown-option';

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                )
            }}
        </PlacesAutocomplete>
        <FavAddressSelect value={favAddresses} onChange={favAddressHandler} />
        </div>
    );
};

export default observer(AddressInput);
