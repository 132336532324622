import React from 'react';
import { useTranslation } from 'react-i18next';

type ComponentParams = {
    children?: any,
}

const Header = ({ children }: ComponentParams) => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    const langOptions = ['fi', 'en'].map(i => <option key={i} value={i}>{i}</option>);

    return (
        <nav className="navbar navbar-dark bg-inverse">
            <a className="navbar-brand" href="/">{t('header.title')}</a>

            {children}

            <select onChange={changeLanguage} value={i18n.languages[0]}>
                {langOptions}
            </select>
        </nav>
    );
};

export default Header;
