import React, { useState } from 'react';
import ReactDOM from 'react-dom';

export const useModal = () => {
    const [isShowingModal, setIsShowing] = useState(false);

    const toggleModal = (toggle:boolean|null = null) => {
        if (toggle === null) {
            setIsShowing(!isShowingModal);
        } else if (typeof toggle === 'boolean') {
            setIsShowing(toggle);
        }
    };

    return {
        isShowingModal,
        toggleModal,
    }
};

type ComponentParams = {
    children: any[],
    isShowing: boolean,
    hide: Function,
}

const Modal = ({children, isShowing/*, hide*/} : ComponentParams) => {
    if (!isShowing) {
        return null
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            <div className="modal-overlay" />

            <div className="modal-wrapper">
                {children}
            </div>
        </React.Fragment>, document.body
    );
};

export default Modal;